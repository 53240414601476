@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter+Moments&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

:root {
  --primary-color: #ff7731;
  --secondary-color: #faa935;
  --heading-color: #0b2727;
  --text-color: #6e7074;
  --font-name: "Inter", sans-serif !important;
  --subtitle-font-name: "Montserrat", cursive;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-family: "Inter", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  list-style: none;
}

b {
  font-weight: bold;
}

.custom-collapse {
  .ant-collapse-header {
    padding-bottom: 0 !important;
  }
}

.step1,
.step2 {
  label {
    font-size: 14px;
  }
  span {
    font-size: 14px;
  }
}

.ant-picker-range {
  .ant-picker-input {
    font-size: 12px !important;
  }
}
.ant-picker-no-left {
  left: 0px !important;
  .ant-picker-time-panel {
    .ant-picker-time-panel-cell.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background-color: #1677ff !important;
        color: white;
      }
    }
  }
}

.step1 {
  .form-search {
    .ant-form-item {
      .ant-select {
        border-color: #e6e8e9;
        height: 48px;
        border-radius: 12px;
        position: relative;
        .ant-select-selector {
          background-color: #f5f6f6 !important;
          padding-left: 38px;
          .ant-select-selection-item {
            padding-right: 0px;
          }
        }
        .ant-select-arrow {
          height: 100%;
          position: absolute;
          left: 10px; /* Adjust as needed */
          top: 6px;
          pointer-events: none;
        }
      }
      .ant-form-item-label {
        label {
          font-size: 14px !important;
          font-weight: 500 !important;
          color: #22313f;
        }
      }
    }
    .form-item-right-label {
      .ant-form-item-label {
        text-align: right;
        label::after {
          display: none;
        }
      }
    }
  }
  .ant-switch.ant-switch-checked {
    background-color: white !important;
    border: 2px solid #0064d2;
    .ant-switch-handle {
      inset-inline-start: calc(100% - 18px);
      top: 0px;
    }
    .ant-switch-handle::before {
      background-color: #0064d2;
    }
  }
  .ant-picker {
    height: 48px !important;
    background-color: #f5f6f6;
    border-radius: 12px;
    .ant-picker-input {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.step-indicator {
  display: flex;
  align-items: center;
  padding: 20px 40px 40px 40px;
  background-color: white;
  border-radius: 0 0 20px 20px;
}

.step {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.step-indicator .step-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: white;
  font-size: 10px;
  text-align: center;
  position: relative;
  line-height: 50px;
  font-size: 16px;
  border: 3px solid #808991;
  color: #808991;
  font-weight: 500;
}

.step.active .step-icon {
  background: #0064d2;
  border-color: #0064d2;
  color: #fff;
}

.step.actived .step-icon {
  background: white;
  border-color: #0064d2;
  color: #0064d2;
}

.step p {
  text-align: center;
  position: absolute;
  color: #808991;
  font-size: 12px;
  font-weight: bold;
  width: 70px;
  bottom: -20px;
}

.step.active p,
.actived p {
  color: #0064d2;
}

.step.step2.step4 p,
.step.step3.step4 p {
  left: 50%;
  transform: translateX(-50%);
}

.indicator-line {
  width: 100%;
  height: 3px;
  background: #808991;
  flex: 1;
}

.indicator-line.active {
  background: #0064d2;
}

.custom-scroll {
  // Customize scrollbar for WebKit browsers (Chrome, Safari, etc.)
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e2e2e2;
    border-radius: 8px;
    border: 1px solid transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #e2e2e2;
  }
}

.bg-step1 {
  background: linear-gradient(240deg, #3796e7 0%, #376ac1 100%);
}

.ant-picker-panel {
  &:last-child {
    width: 0;

    .ant-picker-header {
      position: absolute;
      right: 0;

      .ant-picker-header-prev-btn,
      .ant-picker-header-view {
        visibility: hidden;
      }
    }

    .ant-picker-body {
      display: none;
    }

    @media (min-width: 768px) {
      width: 280px !important;

      .ant-picker-header {
        position: relative;

        .ant-picker-header-prev-btn,
        .ant-picker-header-view {
          visibility: initial;
        }
      }

      .ant-picker-body {
        display: block;
      }
    }
  }
}

.search-flight {
  display: none !important;
}

.waiting-bg-air {
  animation-name: bgAir;
  animation-duration: 20s;
  animation-fill-mode: forwards;
}
.waiting-air {
  animation-name: air;
  animation-duration: 20s;
  animation-fill-mode: forwards;
}

.calender-air {
  scrollbar-width: none;
}

.list-air-flight {
  border-radius: 20px;
  scrollbar-width: none;
}

@keyframes bgAir {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes air {
  from {
    left: 0;
  }
  to {
    left: calc(100% - 25px);
  }
}

.calender-active {
  border: 1px solid #d72f20;
}

.home {
  .ant-spin {
    position: fixed !important;
    height: auto !important;
    top: 50% !important;
  }
}

.waiting-page {
  background: url("./images/bg-loading.jpg") no-repeat;
  background-size: cover;
  .ant-spin {
    position: unset !important;
    svg {
      fill: white;
    }
  }
}

input {
  font-size: 16px !important;
}

textarea {
  font-size: 16px !important;
}

.airport-list {
  max-height: calc(100% - 50px);
}

.ant-select-selector {
  font-size: 16px !important;
}

.ant-drawer-body {
  padding: 0 !important;
  overflow: hidden;
}

.card-filter {
  box-shadow:
    0 0 10px #d9d9d9,
    0 0 20px #d9d9d9,
    0 0 30px #d9d9d9;
  .card-filter-title {
    height: 42px;
    border-bottom: 1px solid #d9d9d9;
    padding: 8px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .card-filter-body {
    padding: 0 8px;
    label {
      border-bottom: 1px dashed #d9d9d9;
      margin-bottom: 0;
      padding: 6px 0;
    }
    label:last-child {
      border-bottom: none;
    }
  }
}

.header-filter {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100vw;
  background: #3875ca;
  font-size: 22px;
  color: white;
  padding: 12px;
}
